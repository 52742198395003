import React, {useState} from 'react';
import {Box, Button, Container, IconButton, Link, Paper, TextField, Typography,} from '@mui/material';
import {Email, Facebook, Instagram} from '@mui/icons-material';

const ContactSection = () => {
    const iconSize = '2rem';
    const iconColor = 'white';

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        request: '',
        eventType: '',
        eventYear: '', // Changed the name to eventYear
    });

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const sendEmail = () => {
        const {name, email, request, eventType, eventYear} = formData;
        const emailBody = `Name: ${name}\nEmail: ${email}\nType of Event: ${eventType}\nYear of Event: ${eventYear}\nRequest: ${request}`;
        window.location.href = `mailto:Zohair729@gmail.com?subject=Contact Request&body=${encodeURIComponent(emailBody)}`;
    };

    return (
        // <div style={{ background: '#050813' }}>
        <div style={{paddingBlock: '5rem'}}>
            <Container
                maxWidth={"lg"}
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h2" sx={{mb: 5}}>
                    Contact
                </Typography>

                <Typography  width={"100%"} textAlign={"left"} variant="h4" fontWeight={800} sx={{mb: 5}}>
                    <u>Let's get in touch!</u>
                </Typography>

                <Paper
                    elevation={5}
                    sx={{
                        padding: '10%',
                        textAlign: 'center',
                        background: 'rgba(255, 255, 255, 0.9)',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                        borderRadius: '12px',
                    }}
                >
                    <Box sx={{mt: 4}}>
                        <Typography variant="h4" sx={{textAlign: "left"}}>
                            Email me
                        </Typography>
                        <form>
                            <TextField
                                fullWidth
                                label="Name"
                                variant="outlined"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                sx={{mt: 2}}
                            />
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                sx={{mt: 2}}
                            />
                            <TextField
                                fullWidth
                                label="Type of Event"
                                variant="outlined"
                                name="eventType"
                                value={formData.eventType}
                                onChange={handleInputChange}
                                sx={{mt: 2}}
                            >
                            </TextField>
                            <TextField
                                fullWidth
                                type="number" // Changed the type to number for the year input
                                label="Year of Event"
                                variant="outlined"
                                name="eventYear"
                                value={formData.eventYear}
                                onChange={handleInputChange}
                                sx={{mt: 2}}
                                inputProps={{min: new Date().getFullYear()}} // Set min attribute to the current year
                            />
                            <TextField
                                fullWidth
                                label="Request"
                                variant="outlined"
                                multiline
                                rows={4}
                                name="request"
                                value={formData.request}
                                onChange={handleInputChange}
                                sx={{mt: 2}}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={sendEmail}
                                sx={{mt: 2}}
                            >
                                Send
                            </Button>
                        </form>
                    </Box>
                </Paper>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3}}>
                    <IconButton
                        component={Link}
                        href="https://www.instagram.com/emcee.zee/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Instagram"
                        sx={{m: 1}}
                    >
                        <Instagram className={"sidebar-link"} sx={{color: iconColor, fontSize: iconSize}}/>
                    </IconButton>
                    <IconButton
                        component={Link}
                        href="https://www.facebook.com/profile.php?id=100094977702126&mibextid=ZbWKwL"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Facebook"
                        sx={{m: 1}}
                        className={"sidebar-link"}
                    >
                        <Facebook className={"sidebar-link"} sx={{color: iconColor, fontSize: iconSize}}/>
                    </IconButton>

                    <IconButton
                        aria-label="Email"
                        sx={{m: 1}}
                        onClick={sendEmail}
                        className={"sidebar-link"}
                    >
                        <Email className={"sidebar-link"} sx={{color: iconColor, fontSize: iconSize}}/>
                    </IconButton>
                </Box>
            </Container>
        </div>
    );
};

export default ContactSection;
