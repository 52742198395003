import React from 'react';
import {Box, Container, Typography} from '@mui/material';
import {motion} from 'framer-motion';

import {useInView} from 'react-intersection-observer';

const AboutMe = () => {



    const paragraphVariants = {
        hiddenL: {opacity: 0, x: -50},
        hiddenR: window.innerWidth >= 960 ? {opacity: 0, x: 50} : {opacity: 0, x: 0},
        hiddenD: {opacity: 0, y: 50},
        hiddenU: {opacity: 0, y: -50},
        hidden: {opacity: 0},
        visible: {opacity: 1, x: 0, y: 0},
    };


    const [ref1, inView1] = useInView({
        threshold: .5
    });

    const [ref2, inView2] = useInView({
        threshold: .5
    });

    const [ref3, inView3] = useInView({
        threshold: .5
    });

    const [ref5, inView5] = useInView({
        threshold: 1
    });

    return (
        <div
            style={{
                paddingTop: '3rem',
                paddingBottom: '8rem',
                background: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0, 0, 0, 0) 50%)',
            }}
        >
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    <motion.div
                        ref={ref1}
                        variants={paragraphVariants}
                        initial="hidden"
                        animate={inView1 ? 'visible' : 'hidden'}
                        transition={{duration: 1}}
                    >
                        <Typography variant={"h2"}>
                            About Me
                        </Typography>

                        <Typography variant={"h3"}>
                            Master of Ceremonies
                        </Typography>
                    </motion.div>

                    <br/><br/>

                    <motion.div
                        ref={ref2}
                        variants={paragraphVariants}
                        initial="hiddenL"
                        animate={inView2 ? 'visible' : 'hiddenL'}
                        transition={{duration: 1}}
                        style={{textAlign: 'left'}}
                    >
                        <Typography variant={"body3"}>
                            Emcee Zee, the only MC you'll ever need.
                        </Typography>
                    </motion.div>

                    <br/><br/>

                    <motion.div
                        ref={ref3}
                        variants={paragraphVariants}
                        initial="hiddenL"
                        animate={inView3 ? 'visible' : 'hiddenL'}
                        transition={{duration: 1}}
                        style={{textAlign: 'left'}}
                    >
                        <Typography variant={"body3"}>
                            Professional, punctual and charismatic - I can pump up the energy and command a room using an "old school" charm.
                        </Typography>
                    </motion.div>

                    <br/><br/>

                    <motion.div
                        ref={ref5}
                        variants={paragraphVariants}
                        initial="hiddenD"
                        animate={inView5 ? 'visible' : 'hiddenD'}
                        transition={{duration: 0.5}}
                    >
                        <Typography variant={"body3"} fontWeight={800}>
                            Available to host all types of weddings and corporate events.
                        </Typography>
                        {/*<Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>*/}
                        {/*    <ThumbUpAltIcon sx={{fontSize: '5rem', marginRight: '0.5rem', color: '#4DA1FF'}}/>*/}
                        {/*    <CheckCircleIcon sx={{fontSize: '5rem', color: '#4DA1FF'}}/>*/}
                        {/*</Box>*/}
                    </motion.div>
                </Box>
            </Container>
        </div>
    );
};

export default AboutMe;
