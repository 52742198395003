import React from 'react';
import HeroSection from '../components/Hero/HeroSection';
import GallerySection from '../components/GallerySection/GallerySection';
import ContactSection from "../components/ContactSection";
// import SectionDivider from "../components/SectionDivider";
import AboutMe from "../components/AboutMe";
import {Box, Typography} from "@mui/material";
import ReviewsSection from "../components/Reviews/ReviewsSection";

const Home = () => {

    return (
        <div>
            <div id="hero-section">
                <HeroSection/>
            </div>
            <div id="about-section">
                <AboutMe/>
            </div>

            <Box id="important-reminder-section"
                 sx={{
                     background: "#c0c0c0",
                     textAlign: 'center', padding: {xs: '40px 20px 40px 20px', sm: '100px'}
                 }}>

                <Typography variant="h3" sx={{fontStyle: 'normal'}}>
                    any event, any time - anywhere in Ontario
                </Typography>
            </Box>

            <div id="gallery-section">
                <GallerySection/>
            </div>
            <div id="reviews-section">
                <ReviewsSection/>
            </div>
            <div id="contact-section">
                <ContactSection/>
            </div>
        </div>
    );
};

export default Home;
