import React, {useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import heroVideo from '../../assets/videos/heroBG.mp4'; // Import the video file
import heroImg from '../../assets/images/heroImg.png'; // Import the video file
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import "./Hero.css";

const texts = [
    "the only emcee you'll ever need!",
];

const HeroSection = () => {
    const [currentText, setCurrentText] = useState(' ');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const textIntervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 5000);

        return () => {
            clearInterval(textIntervalId);
        };
    }, []);

    useEffect(() => {
        const targetText = texts[currentIndex];
        let charIndex = 1;

        const typingInterval = setInterval(() => {
            setCurrentText(targetText.substring(0, charIndex));
            charIndex++;

            if (charIndex > targetText.length) {
                clearInterval(typingInterval);
            }
        }, 50);

        return () => clearInterval(typingInterval);
    }, [currentIndex]);

    const scrollToNextSection = () => {
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth',
        });
    };

    return (
        <Box
            sx={{
                position: 'relative',
                height: '100vh',
            }}
        >
            <video
                autoPlay
                muted
                loop
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    opacity: 0.5,
                    objectFit: "cover"
                }}
            >
                <source src={heroVideo} type="video/mp4"/>
            </video>

            <img
                src={heroImg}
                alt="Zee Overlay"
                className={"hero-image"}
            />


            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(to top, rgba(0,0,0,1), transparent 20%)',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)',
                }}
            >
                <Typography variant="h1">
                    Emcee Zee
                </Typography>
                <Typography variant="h4">
                    {currentText}
                </Typography>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: '65%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                }}
            >
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                }}
            >
                <KeyboardDoubleArrowDownIcon
                    sx={{
                        color: '#ffffff',
                        fontSize: '5rem',
                        cursor: 'pointer',
                        mb: "20%",
                        transition: 'transform 0.3s, font-size 0.3s',
                        '&:hover': {
                            transform: 'scale(1.3)',
                        },
                    }}
                    onClick={scrollToNextSection}
                />
            </Box>
        </Box>
    );
};

export default HeroSection;
