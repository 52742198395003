import React, {useEffect, useState} from 'react';
import {Box, Container, Typography} from '@mui/material';
import Masonry from 'react-masonry-css';
import "./GallerySection.css";


const GallerySection = () => {
    const galleryImages = require.context('../../assets/images/gallery', false, /\.(jpg|jpeg|png)$/);
    const imageKeys = galleryImages.keys();

    const [images, setImages] = useState([]);

    useEffect(() => {
        const loadImages = async () => {
            const loadedImages = await Promise.all(
                imageKeys.map(async (imageKey) => {
                    const module = await import(`../../assets/images/gallery${imageKey.slice(1)}`);
                    return module.default;
                })
            );
            setImages(loadedImages);
        };

        loadImages();
    }, [imageKeys]);


    return (
        <div style={{paddingBlock: '5rem'}}>

            <Box sx={{textAlign: 'center', mb: 4}}>
                <Typography variant={'h2'}>Gallery</Typography>
                <Typography variant={'h3'}>MC in Action</Typography>
            </Box>
            <Container maxWidth={"xl"}>
                <Masonry
                    breakpointCols={{default: 3, 1100: 2, 700: 1}}
                    className="gallery-masonry-grid"
                    columnClassName="gallery-masonry-grid_column"
                >

                    {images.map((image, index) => (
                        <div key={index} className="gallery-masonry-item">
                            <img
                                src={image}
                                alt={`Gallery ${index + 1}`}
                                style={{width: '100%', height: 'auto', cursor: 'pointer'}}
                                loading="lazy"
                            />
                        </div>
                    ))}

                </Masonry>
            </Container>
        </div>
    );
};

export default GallerySection;
