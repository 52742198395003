import React, {useEffect, useState} from 'react';
import {Box, Container, Typography} from '@mui/material';
import Masonry from "react-masonry-css";
import "./Reviews.css";

const ReviewsSection = () => {
    const reviewsImages = require.context('../../assets/images/reviews', false, /\.(jpg|jpeg|png)$/);
    const imageKeys = reviewsImages.keys();

    const [images, setImages] = useState([]);

    useEffect(() => {
        const loadImages = async () => {
            const loadedImages = await Promise.all(
                imageKeys.map(async (imageKey) => {
                    const module = await import(`../../assets/images/reviews${imageKey.slice(1)}`);
                    return module.default;
                })
            );
            setImages(loadedImages);
        };

        loadImages();
    }, [imageKeys]);

    return (
        <div style={{paddingBlock: '5rem', background: "#0e0e0e"}}>

            <Box sx={{textAlign: 'center', mb: 4}}>
                <Typography variant={'h4'}>Here's what some of my clients say about my work</Typography>
                {/*<Typography variant={'h3'}>17 testimonials</Typography>*/}
                <span role="img" aria-label="star" style={{fontStyle: "normal"}}> ⭐️⭐️⭐️⭐️⭐️</span>
            </Box>

            <Container maxWidth={"xl"}>
                <Masonry
                    breakpointCols={{default: 3, 1100: 2, 700: 1}}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >

                    {images.map((image, index) => (
                        <div key={index} className="masonry-item">
                            <img
                                src={image}
                                alt={`Gallery ${index + 1}`}
                                loading="lazy"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    cursor: 'pointer',
                                    borderRadius: '10px', // Adjust the value as needed
                                    marginBottom: '10px', // Gap between each image vertically
                                }}
                            />
                        </div>
                    ))}

                </Masonry>
            </Container>
        </div>
    );
};

export default ReviewsSection;
